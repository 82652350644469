import {
  CSSObject,
  Drawer,
  Grid,
  IconButton,
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  styled,
  Theme,
  Typography,
} from '@mui/material';
import {
  PeopleAltOutlined,
  CreditCard,
  PieChartOutline,
  ArrowBack,
  ArrowForward,
  DashboardOutlined,
  CalculateOutlined,
  QueryStatsOutlined,
  CelebrationOutlined,
  SupportAgent
} from '@mui/icons-material';
//import CurrencyBitcoinIcon from '@mui/icons-material/CurrencyBitcoin';
import BambooLogo from './Svgs/BambooLogo';
import BambooLogoSmall from './Svgs/BambooLogoSmall';
import { FC, useCallback, useState } from 'react';
import Link from 'next/link';
import { useRouter } from 'next/router';
import SmartLink from './ui/smart-link/SmartLink';

const drawerWidth = 280;

const openedMixin = (theme: Theme): CSSObject => ({
  width: drawerWidth,
  height: '100vh',
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: 'hidden',
});

const closedMixin = (theme: Theme): CSSObject => ({
  height: '100vh',
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: 'hidden',
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up('sm')]: {
    width: `calc(${theme.spacing(8)} + 1px)`,
  },
});

type MenuLink = {
  label: string;
  href: string;
  icon: JSX.Element;
};

const links: MenuLink[] = [
  {
    label: 'Overview',
    href: '/',
    icon: <PieChartOutline />,
  },
  {
    label: 'Marketing',
    href: '/marketing',
    icon: <QueryStatsOutlined />,
  },
  {
    label: 'Reconciliation',
    href: '/reconciliation',
    icon: <CalculateOutlined />,
  },
  {
    label: 'Promotions',
    href: '/promotions',
    icon: <CelebrationOutlined />,
  },
  {
    label: 'Payments',
    href: '/payments',
    icon: <CreditCard />,
  },
  // {
  //   label: 'Promotions',
  //   href: '/promotions',
  //   icon: <CurrencyBitcoinIcon />,
  // },
  {
    label: 'Users',
    href: '/users',
    icon: <PeopleAltOutlined />,
  },
  {
    label: 'Events (AU)',
    href: 'http://bamboo-hc-prod-au.s3-website.ap-southeast-2.amazonaws.com/',
    icon: <DashboardOutlined />,
  },
  {
    label: 'Events (US)',
    href: 'http://bamboo-hc-prod-us.s3-website.us-east-2.amazonaws.com/',
    icon: <DashboardOutlined />,
  },
];

const TechRequest: FC<{onClick: () => void}> = ({
  onClick
}) => {

  return (
      <ListItemButton onClick={onClick} >
        <ListItemIcon
          sx={{
            '& svg': {
              color: 'text.secondary',
            },
          }}
        >
          <SupportAgent/>
        </ListItemIcon>

          <ListItemText
            primaryTypographyProps={{
              color:  'text.secondary',
            }}
            primary={"Tech Request"}
          />

      </ListItemButton>
  );
};

const LinkItem: FC<{ link: MenuLink; iconOnly: boolean }> = ({
  link,
  iconOnly,
}) => {
  const { pathname } = useRouter();
  const isSelected = link.href === pathname;

  return (
    <SmartLink url={link.href}>
      <ListItemButton key={link.label} selected={isSelected}>
        <ListItemIcon
          sx={{
            '& svg': {
              color: isSelected ? 'blue.dark' : 'text.secondary',
            },
          }}
        >
          {link.icon}
        </ListItemIcon>
        {!iconOnly && (
          <ListItemText
            primaryTypographyProps={{
              color: isSelected ? 'blue.dark' : 'text.secondary',
            }}
            primary={link.label}
          />
        )}
      </ListItemButton>
    </SmartLink>
  );
};

const CollapsableDrawer = styled(Drawer, {
  shouldForwardProp: (prop) => prop !== 'open',
})(({ theme, open }) => ({
  width: drawerWidth,
  height: '100vh',
  '& .MuiDrawer-paper': {
    width: drawerWidth,
  },
  ...(open && {
    ...openedMixin(theme),
    '& .MuiDrawer-paper': openedMixin(theme),
  }),
  ...(!open && {
    ...closedMixin(theme),
    '& .MuiDrawer-paper': closedMixin(theme),
  }),
}));

const DrawerHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  height: '120px',
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
}));

const DrawerFooter = styled('div')(() => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  height: '120px',
}));

const Navigation: FC = () => {
  const [drawerOpen, setDrawerOpen] = useState(true);

  const handleDrawerToggle = () => {
    setDrawerOpen(!drawerOpen);
  };

  const showCollectorDialog = () => (window as any).showCollectorDialog();

  return (
    <>
      <CollapsableDrawer variant="permanent" open={drawerOpen}>
        <Grid container direction="column" sx={{ height: '100%' }}>
          <Grid item width="100%">
            <DrawerHeader>
              {drawerOpen ? (
                <Link href="/" passHref>
                  <a>
                    <BambooLogo
                      sx={{ color: 'blue.dark', fontSize: '10rem' }}
                    />
                  </a>
                </Link>
              ) : (
                <Link href="/" passHref>
                  <a>
                    <BambooLogoSmall
                      sx={{ color: 'blue.dark', fontSize: '2rem' }}
                    />
                  </a>
                </Link>
              )}
            </DrawerHeader>
          </Grid>
          <Grid item width="100%" xs>
            <List>
              {links.map((link) => (
                <LinkItem key={link.label} link={link} iconOnly={!drawerOpen} />
              ))}
              <TechRequest onClick={showCollectorDialog}/>
            </List>
          </Grid>
          <Grid item width="100%">
            <DrawerFooter>
              {drawerOpen ? (
                <>
                  <IconButton onClick={handleDrawerToggle}>
                    <ArrowBack sx={{ color: 'text.secondary' }} />
                  </IconButton>
                  <Typography color="text.secondary" align="center">
                    Collapse menu
                  </Typography>
                </>
              ) : (
                <IconButton onClick={handleDrawerToggle}>
                  <ArrowForward sx={{ color: 'text.secondary' }} />
                </IconButton>
              )}
            </DrawerFooter>
          </Grid>
        </Grid>
      </CollapsableDrawer>
    </>
  );
};

export default Navigation;
