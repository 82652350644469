import { FC, useEffect, useRef, useState } from 'react';
import { styled } from '@mui/material/styles';
import Navigation from '../components/Navigation';
import {
  Avatar,
  Grid,
  Paper,
  Stack,
  Toolbar,
  Typography,
  Divider,
  MenuList,
  MenuItem,
  ListItemIcon,
  ListItemText,
  Container,
  SxProps,
} from '@mui/material';
import { useAuthUser, useSignOut } from '@bamboard/react-auth-kit';
import { setRegion, useAppContext } from '@bamboard/app-context';
import {
  ArticleOutlined,
  LogoutRounded,
  DoneOutlined,
  ArrowBackOutlined,
} from '@mui/icons-material';
import Flag from 'react-flagkit';
import { visuallyHidden } from '@mui/utils';

const RoundedPaper = styled(Paper)({
  borderRadius: '12px',
});

const Main = styled('main')({
  width: '100vw',
  height: '100vh',
  display: 'flex',
  flexDirection: 'column',
});

const Root = styled('div')({
  display: 'flex',
});

const Content = styled('div')({
  flexGrow: 1,
});

interface UserMenuProps {
  /* eslint-disable-next-line */
  visible: boolean;
}

const UserMenu: FC<UserMenuProps> = ({ visible }) => {
  const logout = useSignOut();

  const [{ region }, dispatch] = useAppContext();
  const [level, setLevel] = useState('main');

  useEffect(() => {
    if (!visible && level !== 'main') {
      setLevel('main');
    }
  }, [visible, level, setLevel]);

  const TopLevel: FC = () => (
    <>
      <MenuItem onClick={() => setLevel('db')}>
        <ListItemIcon>
          <ArticleOutlined />
        </ListItemIcon>
        <ListItemText>Select Database</ListItemText>
        <Flag country={region} />
      </MenuItem>
      <Divider />
      <MenuItem onClick={logout}>
        <ListItemIcon>
          <LogoutRounded />
        </ListItemIcon>
        <ListItemText>Logout</ListItemText>
      </MenuItem>
    </>
  );

  const reset = () => setLevel('main');
  useEffect(reset, [region]);

  const DbLevel: FC = () => (
    <>
      <MenuItem onClick={() => setLevel('main')}>
        <ListItemIcon>
          <ArrowBackOutlined />
        </ListItemIcon>
        <ListItemText>Select Database</ListItemText>
      </MenuItem>
      <MenuItem onClick={() => setRegion(dispatch, 'AU')}>
        <ListItemIcon>
          <Flag country="AU" />
        </ListItemIcon>
        <ListItemText>Australia</ListItemText>
        {region === 'AU' && (
          <ListItemIcon>
            <DoneOutlined />
          </ListItemIcon>
        )}
      </MenuItem>
      <MenuItem onClick={() => setRegion(dispatch, 'US')}>
        <ListItemIcon>
          <Flag country="US" />
        </ListItemIcon>
        <ListItemText>USA</ListItemText>
        {region === 'US' && (
          <ListItemIcon>
            <DoneOutlined />
          </ListItemIcon>
        )}
      </MenuItem>
    </>
  );

  return (
    <RoundedPaper>
      <MenuList sx={{ width: 'inherit' }}>
        {level === 'main' && <TopLevel />}
        {level === 'db' && <DbLevel />}
      </MenuList>
    </RoundedPaper>
  );
};

const TopBar: FC = () => {
  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  const { email } = useAuthUser()()!;
  const [{ pageTitle: PageTitle, region }] = useAppContext();

  const wrapperRef = useRef<HTMLDivElement | null>(null);

  const [visible, setVisible] = useState(false);

  const visability = visible ? {} : visuallyHidden;

  const sx: SxProps = {
    width: '300px',
    position: 'absolute',
    top: '60px',
    zIndex: 99,
    ...visability,
  };

  useEffect(() => {
    function handleClickOutside({ target }: MouseEvent) {
      if (!wrapperRef?.current?.contains(target as Node)) {
        setVisible(false);
      }
    }
    // Bind the event listener
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [wrapperRef]);

  return (
    <Toolbar sx={{ height: '60px' }}>
      <Grid container>
        <Grid item xl={9} xs={6}>
          <Typography variant="h4">
            <PageTitle></PageTitle>
          </Typography>
        </Grid>
        <Grid
          ref={wrapperRef}
          container
          item
          xl={3}
          xs={6}
          justifyContent="center"
        >
          <Stack direction="row" spacing={2} alignItems="center">
            <Stack
              onClick={() => setVisible(!visible)}
              spacing={2}
              direction={'row'}
              alignItems="center"
            >
              <Avatar>{email[0]}</Avatar>
              <Typography noWrap>{email}</Typography>
              <Flag country={region} />
            </Stack>
            <Container sx={sx}>
              <UserMenu visible={visible} />
            </Container>
          </Stack>
        </Grid>
      </Grid>
    </Toolbar>
  );
};

const MainLayout: FC = ({ children }) => {
  return (
    <Root>
      <Navigation />
      <Main>
        <TopBar />
        <Content
          sx={{
            height: 'calc(100vh - 120px)',
            maxHeight: 'calc(100vh - 70px)',
            minHeight: 'calc(100vh - 120px)',
          }}
        >
          {children}
        </Content>
      </Main>
    </Root>
  );
};

export default MainLayout;
