import { SvgIcon, SvgIconProps } from '@mui/material';

const BambooLogo = (props: SvgIconProps) => {
  return (
    <SvgIcon {...props} viewBox="0 0 37 37" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M9.7525 13.9058C9.08065 13.6612 8.33919 13.6935 7.69116 13.9958C7.04313 14.298 6.54158 14.8453 6.2968 15.5175L0.163044 32.38C0.0417723 32.7129 -0.0127754 33.0665 0.00251482 33.4205C0.017805 33.7745 0.102634 34.122 0.252158 34.4433C0.401682 34.7645 0.612971 35.0531 0.873965 35.2926C1.13496 35.5321 1.44055 35.7178 1.77328 35.8392C2.10601 35.9605 2.45936 36.0151 2.81317 35.9998C3.16699 35.9845 3.51432 35.8996 3.83535 35.75C4.15637 35.6004 4.44481 35.389 4.68418 35.1278C4.92355 34.8667 5.10917 34.5609 5.23044 34.228L11.3642 17.3655C11.4857 17.0325 11.5403 16.6787 11.5251 16.3245C11.5098 15.9704 11.4249 15.6227 11.2752 15.3013C11.1255 14.98 10.914 14.6914 10.6527 14.4519C10.3914 14.2125 10.0855 14.0269 9.7525 13.9058Z"
        fill="#001781"
      />
      <path
        d="M22.3176 7.16051C21.7914 6.96884 21.2186 6.94572 20.6786 7.09438C20.1387 7.24304 19.6584 7.5561 19.3042 7.99016C18.4779 5.2775 10.8151 0 8.4248 0C8.4248 1.92386 13.2968 5.79688 16.7428 7.54178C15.1797 7.34726 13.535 7.63904 12.2168 8.43077C14.161 9.59792 16.8342 9.68741 18.8911 8.69338C18.8804 8.71964 18.8697 8.7459 18.86 8.77313L10.2747 32.3799C10.1534 32.7128 10.0988 33.0663 10.1141 33.4204C10.1294 33.7744 10.2142 34.1219 10.3638 34.4431C10.5133 34.7643 10.7246 35.0529 10.9856 35.2924C11.2466 35.532 11.5522 35.7177 11.8849 35.839C12.2176 35.9604 12.571 36.0149 12.9248 35.9996C13.2786 35.9843 13.6259 35.8995 13.947 35.7499C14.268 35.6003 14.5564 35.3888 14.7958 35.1277C15.0352 34.8666 15.2208 34.5608 15.3421 34.2279L23.9293 10.6182C24.1738 9.94584 24.1413 9.20385 23.8391 8.55542C23.5368 7.907 22.9896 7.40524 22.3176 7.16051Z"
        fill="#001781"
      />
      <path
        d="M34.8817 0.416147C34.5489 0.294605 34.1953 0.23991 33.8413 0.255193C33.4874 0.270475 33.1399 0.355434 32.8187 0.50521C32.4976 0.654987 32.2091 0.866641 31.9698 1.12807C31.7305 1.38949 31.545 1.69556 31.424 2.02877L20.3862 32.3797C20.2649 32.7127 20.2104 33.0662 20.2257 33.4202C20.2409 33.7742 20.3258 34.1218 20.4753 34.443C20.6248 34.7642 20.8361 35.0528 21.0971 35.2923C21.3581 35.5318 21.6637 35.7176 21.9964 35.8389C22.3292 35.9602 22.6825 36.0148 23.0363 35.9995C23.3901 35.9842 23.7375 35.8993 24.0585 35.7497C24.3795 35.6001 24.668 35.3887 24.9073 35.1276C25.1467 34.8664 25.3323 34.5606 25.4536 34.2277L36.4934 3.88163C36.6157 3.54826 36.6711 3.19398 36.6563 2.83915C36.6415 2.48431 36.5567 2.1359 36.407 1.81391C36.2572 1.49192 36.0454 1.20269 35.7837 0.96282C35.5219 0.722954 35.2154 0.537176 34.8817 0.416147Z"
        fill="#001781"
      />
    </SvgIcon>
  );
};

export default BambooLogo;
