import Link from 'next/link';
import { FC, ReactNode } from 'react';
import { styled } from '@mui/material';

type AnchorProps = {
  url: string;
  children: ReactNode;
};

const Anchor = styled('a')({
  textDecoration: 'none',
});

const SmartLink: FC<AnchorProps> = ({ children, url }) => {
  const regEx = /^http/;

  return regEx.test(url) ? (
    <Anchor href={url} target="_blank" rel="noreferrer">
      {children}
    </Anchor>
  ) : (
    <Link href={url}>{children}</Link>
  );
};

export default SmartLink;
